<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-img fluid width="50" :src="imgLogo" alt="Login V2" />
            <b-link class="brand-logo">
                <h2 class="brand-text text-white ml-1 mt-1">
                    El Sazón del Dragón
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="w-100 d-lg-flex align-items-center justify-content-center px-5"
                >
                    <b-img fluid :src="imgUrl" alt="Login V2" />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        Bienvenido al Sistema de Checador! 👋
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Por favor inicia sesión con tu cuenta de correo
                        electrónico y contraseña.
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form class="auth-login-form mt-2" @submit.prevent>
                            <!-- email -->
                            <b-form-group
                                label="Correo Electrónico"
                                label-for="login-email"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Correo Electrónico"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="userEmail"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="login-email"
                                        placeholder="correo@ejemplo.com"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password"
                                        >Contraseña</label
                                    >
                                    <!--<b-link :to="{ /*name:'auth-forgot-password-v2'*/ }">
                    <small>Recuperar Contraseña</small>
                  </b-link>-->
                                </div>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Contraseña"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="
                                            errors.length > 0
                                                ? 'is-invalid'
                                                : null
                                        "
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="
                                                    togglePasswordVisibility
                                                "
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- checkbox -->
                            <b-form-group>
                                <b-form-checkbox
                                    id="remember-me"
                                    v-model="status"
                                    name="checkbox-1"
                                >
                                    Recordarme
                                </b-form-checkbox>
                            </b-form-group>
                            <b-overlay
                                :show="ingresando"
                                rounded
                                opacity="0.6"
                                spinner-small
                                spinner-variant="primary"
                                class="d-inline-block"
                                @hidden="!ingresando"
                            >
                                <!-- submit buttons -->
                                <b-button
                                    type="submit"
                                    variant="primary"
                                    block
                                    @click="validationForm"
                                >
                                    Ingresar
                                </b-button>
                            </b-overlay>
                        </b-form>
                    </validation-observer>

                    <!--<b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'page-auth-register-v2'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>-->
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import Logo from "@/assets/images/logo/logo.png";
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import axios from "axios";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import useJwt from "@/auth/jwt/useJwt";

//axios.defaults.withCredentials = true;
//axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export default {
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        VuexyLogo,
        ValidationProvider,
        ValidationObserver,
        BOverlay,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            ingresando: false,
            status: "",
            password: "",
            userEmail: "",
            sideImg: require("@/assets/images/pages/login-v2.svg"),
            // validation rulesimport store from '@/store/index'
            required,
            email,
        };
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
        imgLogo() {
            let img = require("@/assets/images/logo/logo-sushi.png");
            return img;
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === "dark") {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
                return this.sideImg;
            }
            return this.sideImg;
        },
    },
    methods: {
        validationForm() {
            this.$refs.loginValidation.validate().then((success) => {
                //console.log("usuario", this.userEmail, "password", this.password);

                if (success) {
                    this.doLogin();
                }
            });
        },
        doLogin() {
            /**
             * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
             * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
             */
            var that = this;
            that.ingresando = true;

            const data = {
                email: this.userEmail,
                password: this.password,
            };

            axios
                .post("https://api.sushijimmysan.com/api/login", data)
                .then((res) => {
                    //console.log("respuesta_backend_login", res.data);
                    let userdata = res.data.usuario;
                    let roles = res.data.roles;
                    //var role = 'admin';
                    let ability = [];

                    userdata.role = roles;
                    //if userdata.role contains 'admin' then role = 'admin'
                    if (userdata.role.includes("admin")) {
                        ability = [
                            {
                                action: "manage",
                                subject: "all",
                            },
                        ];
                    } else {
                        ability = [
                            {
                                action: "read",
                                subject: "Analitycs",
                            },
                        ];

                        if (userdata.role.includes("checador")) {
                            ability.push({
                                action: "read",
                                subject: "Checador",
                            });
                        } else if (userdata.role.includes("reportes")) {
                            ability.push({
                                action: "read",
                                subject: "Reportes",
                            });
                        }
                    }

                    userdata.ability = ability;
                    const userdata_save = JSON.stringify(userdata);

                    useJwt.setToken(res.data.token);
                    useJwt.setRefreshToken(res.data.token);
                    localStorage.setItem("userData", userdata_save);
                    this.$ability.update(ability);

                    // ? This is just for demo purpose as well.
                    // ? Because we are showing eCommerce app's cart items count in navbar
                    //this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                    // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                    this.$router
                        .replace("/" /*getHomeRouteForLoggedInUser(role)*/)
                        .then(() => {
                            this.$toast({
                                component: ToastificationContent,
                                position: "top-right",
                                props: {
                                    title: `Bienenido ${
                                        userdata.email || userdata.name
                                    }`,
                                    icon: "CoffeeIcon",
                                    variant: "success",
                                    text: `Iniciaste sesión correctamente como ${userdata.role}.`,
                                },
                            });
                        });
                })
                .catch((err) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error al iniciar sesión",
                        text: err.response.data,
                        confirmButtonText: "Entendido",
                        confirmButtonColor: "#3b3b3b",
                    });
                    console.warn("error_login", err);
                    //if error code is 422
                    /*if(err.response.status === 401){
            //console.log(err.response.data.errors);
            //console.log(err.response.data.errors.email[0]);
            //console.log(err.response.data.errors.password[0]);
            //console.log(err.response.da
            Swal.fire({
              icon: 'error',
              title: err.response.data,
              text: err.response.data.error,
              confirmButtonText: 'Entendido',
              confirmButtonColor: '#3b3b3b',
            });
          }*/
                    that.ingresando = false;
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
